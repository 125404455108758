.Layout{
    /* background-color:#F5F5F5; */
    /* background-image: url("../../images/background.webp");
    min-height: 100vh; */
}
.background{
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../images/background.webp") center center;
    background-attachment: fixed;


    background-repeat: no-repeat;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    position: fixed;
}

.nointernet{
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-attachment: fixed;
    /* background: url("./nointernet.jpeg") center ; */
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    position: fixed;

}

.mainarea{
    margin-top: 10vh;
}


