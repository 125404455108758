.work{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    opacity: 0.9;
    overflow: scroll;
    /* overflow: hidden; */
    width: 100%;
    height: 90vh;
    padding: 20px;
}