.edit{
    opacity: 0.9;
}

.bordered{
    border:3px  solid black;
    border-radius: 15px;
}

/* Input{
    min-width: 130px;
} */

.internal-table{
    max-width: 100%;
    overflow: scroll;
}
