.inputArea{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    opacity: 0.9;
    
}

.card{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}